var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("发展历程")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Milestones")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("發展歷程")]) : _vm._e()]), _c("div", {
    staticClass: "box"
  }, [_vm._l(_vm.development, function (items, index) {
    return _c("el-timeline", _vm._l(items.details, function (item, i) {
      return _c("el-timeline-item", {
        attrs: {
          timestamp: items.year,
          placement: "top"
        }
      }, [_c("el-card", [_vm.language == 1 ? _c("h4", [_vm._v(_vm._s(item.month) + "月")]) : _vm._e(), _vm.language == 2 ? _c("h4", [_vm._v(_vm._s(item.month))]) : _vm._e(), _vm.language == 3 ? _c("h4", [_vm._v(_vm._s(item.month) + "月")]) : _vm._e(), _c("div", {
        domProps: {
          innerHTML: _vm._s(item.context)
        }
      })])], 1);
    }), 1);
  }), _c("div", {
    staticClass: "clearfix"
  })], 2)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/fzlc.jpg")
    }
  }), _c("img", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      src: require("../assets/emailBanner.jpg")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };